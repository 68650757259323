import { useEffect, useState, useRef } from 'react';
import coinAddressValidator from 'coin-address-validator';
import ReCAPTCHA from 'react-google-recaptcha';

import topLogo from './logos/top-logo.svg';
import infoIcon from './logos/info.svg';
import errorIcon from './logos/error.svg';

import './App.css';

function App() {
  const [address, setAddress] = useState('');
  const [targetAddress, setTargetAddress] = useState('');
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(undefined);
  const [ftmAmount, setFtm] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');
  const [tnxHash, setTnxHash] = useState('');
  const [addressValid, setAddressValid] = useState(undefined);
  const [reportTime, setReportTime] = useState(Date.now());
  const testnetExplorer = 'https://testnet.ftmscan.com/tx/';
  const testnetAddressExp = 'https://testnet.ftmscan.com/address/';
  const errorDuration = 5000;
  const msgDuration = 10000;
  const reportTimeRef = useRef();
  reportTimeRef.current = reportTime;

  const shrinkAddress = (str) => {
    if (str === undefined) {
      return '';
    }
    if (str.length > 10) {
      return str.substr(0, 5) + '...' + str.substr(str.length - 4, str.length);
    }
    return str;
  };

  const formatAmount = (amount) => {
    amount = parseFloat(amount);
    return amount.toLocaleString('en-US', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3
    });
  };

  const clearError = () => {
    if (Date.now() - reportTimeRef.current < errorDuration) {
      return;
    }
    setError('');
  };

  const clearMsg = () => {
    if (Date.now() - reportTimeRef.current < msgDuration) {
      return;
    }
    setMsg('');
    setTnxHash('');
  };

  const handleAddressChange = (e) => {
    setTargetAddress(e.target.value);
    if (e.target.value === '') {
      setAddressValid(true);
    }
  };

  const validateAddress = () => {
    return coinAddressValidator.validate(targetAddress, 'eth', 'prod');
  };

  const handleGetFTM = () => {
    if (loading) {
      return;
    }

    setError('');
    setMsg('');
    setTnxHash('');

    let isValidAddress = validateAddress();
    if (!isValidAddress) {
      setError('Invalid Opera Testnet Address!');
      setAddressValid(false);
      setReportTime(Date.now());
      setTimeout(() => {
        clearError();
      }, 5000);
      return;
    }

    setAddressValid(true);
    setLoading(true);

    fetch(`https://faucet.fantom.network/api/request/ftm/${targetAddress}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ captchaValue })
    })
      .then((res) => res.json())
      .then(async (result) => {
        if (result.hasOwnProperty('error')) {
          setMsg(
            'Your account ' +
              result.input +
              ' has already ' +
              result.balance +
              'test FTMs!'
          );
          setTimeout(() => {
            clearMsg();
          }, 10000);
        } else if (result.hasOwnProperty('captchaError')) {
          setMsg(result.captchaError);
          setTnxHash('');
          setTimeout(() => {
            clearMsg();
          }, 10000);
        } else if (result.hasOwnProperty('timeerror')) {
          setMsg(result.timeerror);
          setTnxHash('');
          setTimeout(() => {
            clearMsg();
          }, 10000);
        } else {
          setMsg(
            '5 Testnet FTMs successfully sent to ' +
              shrinkAddress(targetAddress) +
              '.'
          );
          if (result.transactionHash) {
            setTnxHash(result.transactionHash);
            setFtm(result.remainingBalance);
            setReportTime(Date.now());
            setTimeout(() => {
              clearMsg();
            }, 10000);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setError('Invalid Opera Testnet Address!');
        setTnxHash('');
        setReportTime(Date.now());
        setLoading(false);
        setTimeout(() => {
          clearError();
        }, 5000);
      });
  };

  const handleClearAddress = () => {
    setAddressValid(true);
    setTargetAddress('');
    setError('');
  };

  useEffect(() => {
    fetch(`https://faucet.fantom.network/api/status/ftm`)
      .then((res) => res.json())
      .then((result) => {
        if (result.address) {
          setAddress(result.address);
          setFtm(result.balance);
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  }, []);

  const captchaVerification = (value) => {
    setCaptchaValue(value);
    setCaptchaSuccess(true);
  };

  return (
    <div className='App'>
      <div className='App-header'>
        <img src={topLogo} className='App-logo' alt='logo' />
      </div>
      <div className='App-body'>
        <div className='App-body-form'>
          <div className='App-title'>Testnet Opera Faucet</div>
          <div className='App-description'>
            Feel free to get test FTMs to your wallet
          </div>
          <div className='App-address-container'>
            <input
              value={targetAddress}
              placeholder='Input your testnet FTM address'
              onChange={handleAddressChange}
              className={`${addressValid === false ? 'error' : ''}`}
            />
            <button className='Address-error-btn' onClick={handleClearAddress}>
              <img src={errorIcon} alt='error' />
            </button>
          </div>
          <div className='App-bottom-error Red'>
            {error.length > 0 ? error : '\u00A0'}
          </div>
          <div
            style={{
              display: 'block',
              marginLeft: '55px',
              padding: '10px'
            }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={captchaVerification}
            />
          </div>
          <button
            className='App-request-btn'
            onClick={handleGetFTM}
            disabled={targetAddress.length === 0 || !captchaSuccess || loading}>
            {loading ? 'Requesting FTMs' : 'Request Testnet FTM'}
          </button>
          <div
            className={`App-notification ${tnxHash.length === 0 ? 'Red' : ''}`}>
            {msg.length > 0 ? msg : '\u00A0'}
            {tnxHash.length > 0 && (
              <>
                &nbsp;Click&nbsp;
                <a
                  href={testnetExplorer + tnxHash}
                  target='_blank'
                  rel='noreferrer'
                  className='tx-link'>
                  here
                </a>
                &nbsp;to view the transaction.
              </>
            )}
          </div>
          {address.length > 0 && (
            <>
              <div className='App-bottom-status'>
                Testnet Opera FTMs are served from
                <br />
                <a
                  href={testnetAddressExp + address}
                  target='_blank'
                  rel='noreferrer'>
                  {shrinkAddress(address)}
                </a>
                &nbsp; with &nbsp;
                <a
                  href={testnetAddressExp + address}
                  target='_blank'
                  rel='noreferrer'>
                  {formatAmount(ftmAmount)}
                </a>
                &nbsp; Testnet FTMs.
              </div>
            </>
          )}
          <div className='App-bottom-info'>
            <img src={infoIcon} className='info-icon' alt='' />
            <span>
              You can request 5 Testnet FTMs once per address every 5 minutes.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
